import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';
import get from 'get-value';
import {clearAlerts} from '../../../actions/alertsActions';
import Loader from '../../Loader';
import {
    EASY_BASKET_FORM,
    MR_TM_ASE_FORM,
} from '../../../constants/formConstants';
import set from 'set-value';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import MethodRepairLaborTimeMasterForm from './forms/MethodRepairLaborTimeMasterForm';
import EasyBasketMasterForm from './forms/EasyBasketMasterForm';
import {initNewAseTicket, transformNewTicketForSave} from '../Utils';
import {resetAseTicket, saveAseTicket} from '../../../actions/ase/actionAseTicket';
import {createRights} from '../../../constants/aseTicketRights';
import NoBirComponent from '../../common/NoBirComponent';
import {createReqFields} from '../../../constants/aseRequiredFields';
import UploadingAseAttachments from './UploadingAseAttachments';
import {fetchAdminClientIds} from '../../../actions/admin/actionAdminClientIds';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import PageHeader from '../../common/PageHeader';
import PropTypes from 'prop-types';
import checkRoles from '../../common/roleChecker/RoleChecker';

class NewAseTicketPage extends Component {
    constructor(props) {
        super(props);
        this.state = {ticketSubmittedAndShouldRedirect: false};
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.resetAseTicket();
        this.props.fetchAdminClientIds();
    }


    handleSubmit = (values) => {
        const dataForSend = transformNewTicketForSave(values.newValues);
        this.props.saveAseTicket(dataForSend);
        this.setState({ticketSubmittedAndShouldRedirect: true});

    };

    render() {
        const {
            formType,
            isLoadingClientIds,
            ticketData,
            attachmentsData,
            clientIds,
            group,
        } = this.props;

        if (Array.isArray(attachmentsData.attachmentsForUpload) && (attachmentsData.attachmentsForUpload.length > 0)) {
            return <UploadingAseAttachments attachments={attachmentsData.attachmentsForUpload}
                                                attachmentsUrl={ticketData.newAttachments}
                                                correlationId={ticketData.correlationId}
                                                isAttachmentUploading={attachmentsData.isTicketAttachmentUploading}
                                                formType={formType}/>
        }

        if (this.state.ticketSubmittedAndShouldRedirect && !ticketData.isTicketCreating && !ticketData.isTicketLoading && get(ticketData, 'ticket.uuid', {default: false})) {
            return <Redirect to={{
                pathname: `/ase/view/${ticketData.ticket.uuid}`,
                redirectedAfterUpdate: true
            }}/>;
        }

        if (!formType
            || this.state.ticketSubmittedAndShouldRedirect
            || isLoadingClientIds
            || clientIds.isLoading) {
            return <Loader/>;
        }

        const initializationValue = initNewAseTicket(this.props.userDetail, formType);
        if (!initializationValue) {
            return <NoBirComponent formType={formType}/>;
        }

        const requiredFields = createReqFields[createReqFields[group] ? group : 'DEFAULT'][formType];

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={<ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>}/>
                <div>
                    {formType === MR_TM_ASE_FORM &&
                    <MethodRepairLaborTimeMasterForm form={formType}
                                                  initialValues={set(initializationValue, 'ticketSection.formType', MR_TM_ASE_FORM)}
                                                  userRights={createRights}
                                                  reqFields={requiredFields}
                                                  enableReinitialize={true}
                                                  handleSubmit={this.handleSubmit}/>
                    }
                    {formType === EASY_BASKET_FORM &&
                    <EasyBasketMasterForm form={formType}
                                              initialValues={set(initializationValue, 'ticketSection.formType', EASY_BASKET_FORM)}
                                              userRights={createRights}
                                              reqFields={requiredFields}
                                              enableReinitialize={true}
                                              handleSubmit={this.handleSubmit}/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userDetail: state.profile.userDetail,
    group: state.profile.userDetail.group,
    isLoadingClientIds: state.adminClientIds.isLoading,
    ticketData: state.aseTicket,
    attachmentsData: state.aseAttachment,
    clientIds: state.adminClientIds,
});

NewAseTicketPage.propTypes = {
    clearAlerts: PropTypes.func.isRequired,
    fetchAdminClientIds: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
    isLoadingClientIds: PropTypes.bool.isRequired,
    saveAseTicket: PropTypes.func.isRequired,
    ticketData: PropTypes.object.isRequired,
    attachmentsData: PropTypes.object.isRequired,
    clientIds: PropTypes.object.isRequired,
};

export default checkRoles(withRouter(connect(mapStateToProps, {
    clearAlerts,
    saveAseTicket,
    fetchAdminClientIds,
    resetAseTicket,
})(NewAseTicketPage)), ['AS_DEALER']);

