import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    WRONG_OR_NOT_COMPLETE: {
        id: 'ase.rootCause.option.wrongOrNotComplete',
        defaultMessage: 'Wrong or not complete'
    },
    MISSING_DOCUMENTATION: {
        id: 'ase.rootCause.option.missingDocumentation',
        defaultMessage: 'Missing documentation'
    },
    INCLUDE_OR_NOT_INCLUDE_ERROR: {
        id: 'ase.rootCause.option.includeOrNotIncludeError',
        defaultMessage: '"Include" or "Not include" error'
    },
    APPLICATION_CODE_ERROR: {
        id: 'ase.rootCause.option.applicationCodeError',
        defaultMessage: 'Application code error'
    },
    MISSING_OR_WRONG_LINK: {
        id: 'ase.rootCause.option.missingOrWrongLink',
        defaultMessage: 'Missing or wrong link'
    },
    MISSING_OR_NOT_COMPLETE_ILLUSTRATION: {
        id: 'ase.rootCause.option.missingOrNotCompleteIllustration',
        defaultMessage: 'Missing or not complete illustration'
    },
    EXPLODED_VIEW_NOT_CLEAR: {
        id: 'ase.rootCause.option.explodedViewNotClear',
        defaultMessage: 'Exploded view not clear'
    },
    DOCUMENTATION_IS_CORRECT: {
        id: 'ase.rootCause.option.correctDocumentation',
        defaultMessage: 'Documentation is correct'
    },
    PUBLICATION_DELAY: {
        id: 'ase.rootCause.option.publicationDelay',
        defaultMessage: 'Publication delay'
    },
    TRANSLATION_ERROR: {
        id: 'ase.rootCause.option.translationError',
        defaultMessage: 'Translation error'
    },
    MISSING_PARTS: {
        id: 'ase.rootCause.option.missingParts',
        defaultMessage: 'Missing part(s)'
    },
    MISSING_LABOR_TIME: {
        id: 'ase.rootCause.option.missingLaborTime',
        defaultMessage: 'Missing labor time'
    },
    REPAIR_METHOD_NOT_CORRECT: {
        id: 'ase.rootCause.option.repairMethodNotCorrect',
        defaultMessage: 'Repair method not correct'
    },
    PART_OR_LABOR_TIME_GROUP_NOT_CORRECT_: {
        id: 'ase.rootCause.option.partOrLaborTimeGroupNotCorrect',
        defaultMessage: 'Part or labor time group not correct'
    }
});

export default messages;
