import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    ASE: {
        id: 'form.type.domain.ase',
        defaultMessage: 'After Sales Engineering'
    },
    MR_TM_ASE: {
        id: 'form.type.ase.mrMt',
        defaultMessage: 'Repair Method and Labor Time Request'
    },
    EASY_BASKET: {
        id: 'form.type.ase.easyBasket',
        defaultMessage: 'Easy Basket'
    },
    ASE_INTERNAL_TICKET: {
        id: 'form.type.ase.internalTicket',
        defaultMessage: 'Internal Ticket'
    },
});

export default messages;
