export const HOME_PATH = '/';
export const LOGOUT_PATH = '/logout';

export const PROFILE_PATH = '/profile';

export const ADMINISTRATION_PATH = '/administration';
export const HELP_PATH = '/help';

export const ADMINISTRATION_CLIENT_ID_PATH = `${ADMINISTRATION_PATH}/clientId`;

export const ASE_PATH = '/ase';
export const ASE_NEW_PATH = `${ASE_PATH}/new`;
export const ASE_LIST_PATH = `${ASE_PATH}/list`;
export const ASE_EXPORT_PATH = `${ASE_PATH}/export`;
export const ASE_HISTORY_PATH = `${ASE_PATH}/history`;
export const ASE_FULL_TEXT_SEARCH_PATH = `${ASE_PATH}/fullTextSearch`;
export const ASE_RECURRENT_PROBLEM_PATH = `${ASE_PATH}/recurrentProblem`;
export const ASE_INTERNAL_TICKET = `${ASE_PATH}/internalTicket`;

export const ASE_NEW_REPAIR_METHOD = `${ASE_NEW_PATH}/RepairMethod`;
export const ASE_NEW_EASY_BASKET = `${ASE_NEW_PATH}/EasyBasket`;
export const ASE_NEW_INTERNAL_TICKET = `${ASE_INTERNAL_TICKET}/new`;
export const ASE_INTERNAL_TICKET_LIST_PATH = `${ASE_INTERNAL_TICKET}/list`;
export const ASE_INTERNAL_TICKET_EXPORT_PATH = `${ASE_INTERNAL_TICKET}/export`;
export const ASE_INTERNAL_TICKET_HISTORY_PATH = `${ASE_INTERNAL_TICKET}/history`;
export const ASE_INTERNAL_TICKET_SEARCH_PATH = `${ASE_INTERNAL_TICKET}/fullTextSearch`;

export const ADMINISTRATION_ASE_DEALER_NOTIFICATION_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/dealerNotification`;
export const ADMINISTRATION_ASE_MAIN_PAGE_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/mainPage`;
export const ADMINISTRATION_ASE_RECURRENT_PROBLEM_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/recurrentProblem`;
export const ADMINISTRATION_ASE_WORKING_HOUR_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/workingHour`;
export const ADMINISTRATION_ASE_TICKET_SETTING_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/ticketSetting`;
export const ADMINISTRATION_ASE_ADMIN_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/admin`;
export const ADMINISTRATION_ASE_DEALER_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/dealer`;
export const ADMINISTRATION_ASE_EDITOR_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/editor`;
export const ADMINISTRATION_ASE_DEFAULT_COUNTRY_ROLE_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/defaultCountryRole`;
export const ADMINISTRATION_ASE_SOLUTION_GROUP_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/solutionGroup`;
export const ADMINISTRATION_ASE_GROUP_NEW_PATH = `${ADMINISTRATION_ASE_SOLUTION_GROUP_PATH}/new`;
export const ADMINISTRATION_ASE_SOLUTION_GROUP_LIST_PATH = `${ADMINISTRATION_ASE_SOLUTION_GROUP_PATH}/list`;
export const ADMINISTRATION_ASE_SOLUTION_GROUP_USER_PATH = `${ADMINISTRATION_ASE_SOLUTION_GROUP_PATH}/user`;
export const ADMINISTRATION_ASE_ESCALATION_SCHEMA_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/escalationSchema`;
export const ADMINISTRATION_ASE_ESCALATION_SCHEMA_NEW_PATH = `${ADMINISTRATION_ASE_ESCALATION_SCHEMA_PATH}/new`;
export const ADMINISTRATION_ASE_ESCALATION_SCHEMA_LIST_PATH = `${ADMINISTRATION_ASE_ESCALATION_SCHEMA_PATH}/list`;
export const ADMINISTRATION_ASE_ANSWERING_SCHEMA_PATH = `${ADMINISTRATION_PATH}${ASE_PATH}/answeringSchema`;
export const ADMINISTRATION_ASE_ANSWERING_SCHEMA_NEW_PATH = `${ADMINISTRATION_ASE_ANSWERING_SCHEMA_PATH}/new`;
export const ADMINISTRATION_ASE_ANSWERING_SCHEMA_LIST_PATH = `${ADMINISTRATION_ASE_ANSWERING_SCHEMA_PATH}/list`;
