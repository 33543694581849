import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    ASE_EXPORT_PATH,
    ASE_FULL_TEXT_SEARCH_PATH,
    ASE_HISTORY_PATH,
    ASE_INTERNAL_TICKET_EXPORT_PATH,
    ASE_INTERNAL_TICKET_HISTORY_PATH,
    ASE_INTERNAL_TICKET_LIST_PATH,
    ASE_INTERNAL_TICKET_SEARCH_PATH,
    ASE_LIST_PATH,
    ASE_NEW_EASY_BASKET,
    ASE_NEW_INTERNAL_TICKET,
    ASE_NEW_REPAIR_METHOD,
    ASE_RECURRENT_PROBLEM_PATH,
} from '../../../../routes/paths';
import {moduleRoles} from '../../../../utils/roles';
import formTypeMessages from '../../../../intl/common/formTypeMessages';
import {
    ASE_INTERNAL_TICKET_FORM,
    EASY_BASKET_FORM,
    MR_TM_ASE_FORM
} from '../../../../constants/formConstants';
import menuMessages from '../../../../intl/layout/menuMessages';
import {domains} from '../../../../constants/Utils';


export const getAseEditorMenu = roles => {
    const aseNewEditorOrderSubmenu = buildAseNewEditorOrderSubmenu(roles);

    const result = [
        {
            label: <FormattedMessage {...menuMessages.ASE_LIST}/>,
            to: ASE_LIST_PATH,
            id: ASE_LIST_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.ASE_HISTORY}/>,
            to: ASE_HISTORY_PATH,
            id: ASE_HISTORY_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.ASE_FULLTEXT_SEARCH}/>,
            to: ASE_FULL_TEXT_SEARCH_PATH,
            id: ASE_FULL_TEXT_SEARCH_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.ASE_RECURRENT_PROBLEM}/>,
            to: ASE_RECURRENT_PROBLEM_PATH,
            id: ASE_RECURRENT_PROBLEM_PATH
        },
    ];

    if (aseNewEditorOrderSubmenu.length > 0) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[ASE_INTERNAL_TICKET_FORM]}/>,
                content: aseNewEditorOrderSubmenu
            }
        );
    }


    if (moduleRoles.isAdminAse(roles) || moduleRoles.isSGAdminAse(roles)) {
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ASE_EXPORT}/>,
                to: ASE_EXPORT_PATH,
                id: ASE_EXPORT_PATH
            }
        );
    }

    return result;
};

export const getAseDealerMenu = roles => {
    const aseNewOrderSubmenu = buildAseNewOrderSubmenu(roles);

    const result = [
        {
            label: <FormattedMessage {...menuMessages.ASE_LIST}/>,
            to: ASE_LIST_PATH,
            id: ASE_LIST_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.ASE_HISTORY}/>,
            to: ASE_HISTORY_PATH,
            id: ASE_HISTORY_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.ASE_FULLTEXT_SEARCH}/>,
            to: ASE_FULL_TEXT_SEARCH_PATH,
            id: ASE_FULL_TEXT_SEARCH_PATH
        },
        {
            label: <FormattedMessage {...menuMessages.ASE_RECURRENT_PROBLEM}/>,
            to: ASE_RECURRENT_PROBLEM_PATH,
            id: ASE_RECURRENT_PROBLEM_PATH
        }
    ];


    if (buildAseNewOrderSubmenu.length > 0) {
        result.unshift(
            {
                label: <FormattedMessage {...menuMessages.ASE_NEW}/>,
                content: aseNewOrderSubmenu
            }
        );
    }

    return result;
};


const buildAseNewOrderSubmenu = roles => {
    const result = [];

    const formTypes = moduleRoles.getDealerForms(domains.ASE, roles).map(formType => formType.group);

    if (formTypes.includes(MR_TM_ASE_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[MR_TM_ASE_FORM]}/>,
                to: ASE_NEW_REPAIR_METHOD,
                id: ASE_NEW_REPAIR_METHOD
            }
        );
    }
    if (formTypes.includes(EASY_BASKET_FORM)) {
        result.push(
            {
                label: <FormattedMessage {...formTypeMessages[EASY_BASKET_FORM]}/>,
                to: ASE_NEW_EASY_BASKET,
                id: ASE_NEW_EASY_BASKET
            }
        );
    }
    return result;
};


const buildAseNewEditorOrderSubmenu = roles => {
    const result = [];

    if(moduleRoles.isSGEditor(roles, domains.ASE)){
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ASE_NEW}/>,
                to: ASE_NEW_INTERNAL_TICKET,
                id: ASE_NEW_INTERNAL_TICKET
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ASE_LIST}/>,
                to: ASE_INTERNAL_TICKET_LIST_PATH,
                id: ASE_INTERNAL_TICKET_LIST_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ASE_HISTORY}/>,
                to: ASE_INTERNAL_TICKET_HISTORY_PATH,
                id: ASE_INTERNAL_TICKET_HISTORY_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ASE_INTERNAL_SEARCH}/>,
                to: ASE_INTERNAL_TICKET_SEARCH_PATH,
                id: ASE_INTERNAL_TICKET_SEARCH_PATH
            }
        );
        result.push(
            {
                label: <FormattedMessage {...menuMessages.ASE_INTERNAL_EXPORT}/>,
                to: ASE_INTERNAL_TICKET_EXPORT_PATH,
                id: ASE_INTERNAL_TICKET_EXPORT_PATH
            }
        );
    }

    return result;
};
