import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {confirmAsePopUpModal, fetchAsePopUp, openAsePopUpModal} from '../../actions/ase/actionAsePopUpModal';
import PropTypes from 'prop-types';
import {
    ASE_EXPORT_PATH,
    ASE_FULL_TEXT_SEARCH_PATH,
    ASE_HISTORY_PATH,
    ASE_INTERNAL_TICKET, ASE_INTERNAL_TICKET_EXPORT_PATH,
    ASE_INTERNAL_TICKET_HISTORY_PATH,
    ASE_INTERNAL_TICKET_LIST_PATH,
    ASE_INTERNAL_TICKET_SEARCH_PATH,
    ASE_LIST_PATH,
    ASE_NEW_EASY_BASKET,
    ASE_NEW_INTERNAL_TICKET,
    ASE_NEW_REPAIR_METHOD,
    ASE_PATH,
    ASE_RECURRENT_PROBLEM_PATH,
} from '../../routes/paths';
import ListAsePage from './list/ListAsePage';
import HistoryAsePage from './history/HistoryAsePage';
import NewAseTicketPage from './ticket/NewAseTicketPage';
import get from 'get-value';
import ViewAseTicketPage from './ticket/ViewAseTicketPage';
import EditAseTicketPage from './ticket/EditAseTicketPage';
import {domains} from '../../constants/Utils';
import NotFoundPage from '../NotFoundPage';
import {ASE_INTERNAL_TICKET_FORM, EASY_BASKET_FORM, MR_TM_ASE_FORM} from '../../constants/formConstants';
import Loader from '../Loader';
import AsePopUpModal from './AsePopUpModal';
import RecurrentProblemAsePage from './recurrentProblem/RecurrentProblemAsePage';
import NewAseInternalTicketPage from './internalTicket/NewAseInternalTicketPage';
import ViewAseInternalTicketPage from './internalTicket/ViewAseInternalTicketPage';
import EditAseInternalTicketPage from './internalTicket/EditAseInternalTicketPage';
import ListAseInternalTicketPage from './internalTicketList/ListAseInternalTicketPage';
import HistoryAseInternalTicketPage from './internalTicketHistory/HistoryAseInternalTicketPage';
import InternalTicketSearchAsePage from './internalTicketSearch/InternalTicketSearchAsePage';
import FullTextSearchAsePage from './fullTextSearch/FullTextSearchAsePage';
import ExportAsePage from './export/ExportAsePage';
import InternalTicketExportAsePage from './internalTicketExport/InternalTicketExportAsePage';

const domain = domains.ASE;

class AfterSales extends Component {
    componentDidMount() {
        if (!this.props.isConfirm && this.props.userDetail.dealerNumber && this.props.userDetail.dealerNumber !== '' &&  get(this.props.userDetail, `roles.${domain}.dealer`)) {
            this.props.fetchAsePopUp(this.props.group);

        }
    }

    componentDidUpdate() {
        if (this.props.popUp.disabled === true) { // disabled means isActive :D
            this.props.openAsePopUpModal();
        }
    }

    render() {
        const {userDetail, isOpen, confirmAsePopUpModal, isConfirm, isLoadingPopUp} = this.props;
        const dealer = get(userDetail, `roles.${domain}.dealer`);
        if (dealer && !isConfirm && this.props.popUp.disabled === true) {
            return isLoadingPopUp ? <Loader/> : <AsePopUpModal show={isOpen}
                                                               handleClose={confirmAsePopUpModal}/>;
        }
        return (
            <Switch>
                <Route exact path={ASE_PATH}
                       render={() => dealer ? <Redirect to={ASE_RECURRENT_PROBLEM_PATH}/> :
                           <Redirect to={ASE_LIST_PATH}/>}/>
                <Route exact path={ASE_LIST_PATH}
                       component={ListAsePage}/>
                <Route exact path={ASE_PATH + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewAseTicketPage}/>
                <Route exact path={ASE_PATH + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditAseTicketPage}/>
                <Route exact path={ASE_HISTORY_PATH}
                       component={HistoryAsePage}/>
                <Route exact path={ASE_FULL_TEXT_SEARCH_PATH}
                       component={FullTextSearchAsePage}/>
                <Route exact path={ASE_EXPORT_PATH}
                       component={ExportAsePage}/>
                <Route exact path={ASE_NEW_REPAIR_METHOD}
                       render={() => <NewAseTicketPage formType={MR_TM_ASE_FORM}/>}/>
                <Route exact path={ASE_NEW_EASY_BASKET}
                       render={() => <NewAseTicketPage formType={EASY_BASKET_FORM}/>}/>
                <Route exact path={ASE_NEW_INTERNAL_TICKET}
                       render={() => <NewAseInternalTicketPage formType={ASE_INTERNAL_TICKET_FORM}/>}/>
                <Route exact path={ASE_INTERNAL_TICKET + '/:view(view)/:ticketUuid(.*)'}
                       component={ViewAseInternalTicketPage}/>
                <Route exact path={ASE_INTERNAL_TICKET + '/:edit(edit)/:ticketUuid(.*)'}
                       component={EditAseInternalTicketPage}/>
                <Route exact path={ASE_INTERNAL_TICKET_LIST_PATH}
                       component={ListAseInternalTicketPage}/>
                <Route exact path={ASE_INTERNAL_TICKET_HISTORY_PATH}
                       component={HistoryAseInternalTicketPage}/>
                <Route exact path={ASE_INTERNAL_TICKET_SEARCH_PATH}
                       component={InternalTicketSearchAsePage}/>
                <Route exact path={ASE_INTERNAL_TICKET_EXPORT_PATH}
                       component={InternalTicketExportAsePage}/>
                <Route exact path={ASE_RECURRENT_PROBLEM_PATH}
                       component={RecurrentProblemAsePage}/>
                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

AfterSales.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isConfirm: PropTypes.bool.isRequired,
    openAsePopUpModal: PropTypes.func.isRequired,
    confirmAsePopUpModal: PropTypes.func.isRequired,
    fetchAsePopUpModal: PropTypes.func.isRequired,
    userDetail: PropTypes.object.isRequired,
    popUp: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isOpen: state.asePopUpModal.isOpen,
    isConfirm: state.asePopUpModal.isConfirm,
    userDetail: state.profile.userDetail,
    isLoadingPopUp: state.asePopUpModal.isLoading,
    group: state.profile.userDetail.group,
    popUp: state.asePopUpModal.popUp,
});

export default withRouter(connect(mapStateToProps, {
    openAsePopUpModal,
    confirmAsePopUpModal,
    fetchAsePopUp
})(AfterSales));
