import {defineMessages} from 'react-intl';

const messages = defineMessages({
    UPLOADING_ATTACHMENTS: {
        id: 'ase.ticket.attachments.uploadingAttachments',
        defaultMessage: 'Ticket was saved, uploading attachments...'
    },
    TITLE: {
        id: 'ase.ticket.title',
        defaultMessage: 'Fulltext Search'
    },
    TICKET: {
        id: 'ase.ticket.ticket',
        defaultMessage: 'Ticket'
    },
    TICKET_SECTION: {
        id: 'ase.ticket.header.dealerInformation',
        defaultMessage: 'Dealer information'
    },
    DEALER_NAME: {
        id: 'ase.ticket.label.dealerName',
        defaultMessage: 'Dealer Name'
    },
    DEALER_NUMBER: {
        id: 'ase.ticket.label.dealerNumber',
        defaultMessage: 'Dealer No.'
    },
    CLIENT_ID: {
        id: 'ase.ticket.label.clientId',
        defaultMessage: 'Client ID'
    },
    REGION: {
        id: 'ase.ticket.label.region',
        defaultMessage: 'Region'
    },
    EMAIL: {
        id: 'ase.ticket.label.email',
        defaultMessage: 'E-mail'
    },
    EDITOR_IPN: {
        id: 'ase.ticket.label.lastEditor',
        defaultMessage: 'Last Editor'
    },
    TELEPHONE_NUMBER: {
        id: 'ase.ticket.label.telephoneNumber',
        defaultMessage: 'Telephone number'
    },
    REQUESTOR_ROLE: {
        id: 'ase.ticket.label.requestorRole',
        defaultMessage: 'Role'
    },
    CREATION_DATE: {
        id: 'ase.ticket.label.creationDate',
        defaultMessage: 'Creation Date'
    },
    ENGINE_TYPE: {
        id: 'ase.ticket.label.engineTypeAndSuffix',
        defaultMessage: 'Engine Type & Suffix'
    },
    ENGINE_SERIAL_NUMBER: {
        id: 'ase.ticket.label.engineSerialNumber',
        defaultMessage: 'Engine serial number'
    },
    GEARBOX_TYPE: {
        id: 'ase.ticket.label.gearboxType',
        defaultMessage: 'Gearbox Type & Suffix'
    },
    GEARBOX_SERIAL_NUMBER: {
        id: 'ase.ticket.label.gearboxSerialNumber',
        defaultMessage: 'Gearbox serial number'
    },
    REQUESTOR_NAME: {
        id: 'ase.ticket.label.requestorName',
        defaultMessage: 'Requestor Name'
    },
    REQUESTOR_IPN: {
        id: 'ase.ticket.label.requestorIpn',
        defaultMessage: 'Requestor Ipn'
    },
    SOLUTION_GROUP: {
        id: 'ase.ticket.label.solutionGroup',
        defaultMessage: 'Solution Group'
    },
    PRIORITY_TREATMENT: {
        id: 'ase.ticket.label.priorityTreatment',
        defaultMessage: 'Priority Treatment'
    },
    PRIORITY_CASES: {
        id: 'ase.ticket.label.priorityCases',
        defaultMessage: 'Priority Cases'
    },
    NETWORK_TICKET_NUMBER: {
        id: 'ase.ticket.label.networkTicketNumber',
        defaultMessage: 'Original Associated Network Ticket Number'
    },
    ROOT_CAUSE: {
        id: 'ase.ticket.label.rootCause',
        defaultMessage: 'Root Cause'
    },
    REQUEST_DETAIL_SECTION: {
        id: 'ase.ticket.header.requestDetailSection',
        defaultMessage: 'Request Detail'
    },
    MORE_DETAILS: {
        id: 'ase.ticket.label.moreDetails',
        defaultMessage: 'More details'
    },
    PROBLEM_DESCRIPTION_SECTION: {
        id: 'ase.ticket.header.problemDescriptionSection',
        defaultMessage: 'Problem Description'
    },
    INTERNAL_TICKET_REFERENCES: {
        id: 'ase.ticket.header.internalTicketReferences',
        defaultMessage: 'Internal Ticket References'
    },
    PR: {
        id: 'ase.ticket.label.pr',
        defaultMessage: 'PR'
    },
    PROBLEM_DESCRIPTION: {
        id: 'ase.ticket.label.problemDescription',
        defaultMessage: 'Problem Description'
    },
    TEMPORARY_SOLUTION: {
        id: 'ase.ticket.label.temporarySolution',
        defaultMessage: 'Temporary solution found by dealer'
    },
    NOD_ID: {
        id: 'ase.ticket.label.nodId',
        defaultMessage: 'Nod ID'
    },
    DOCUMENT_ID: {
        id: 'ase.ticket.label.repairMethodOrLaborTime',
        defaultMessage: 'Repair Method/Labor time number'
    },
    VEHICLE_COVERED_BY_WARRANTY: {
        id: 'ase.ticket.label.vehicleCoveredByWarranty',
        defaultMessage: 'Vehicle covered by warranty'
    },
    ISSUE_ORIGIN: {
        id: 'ase.ticket.label.issueOrigin',
        defaultMessage: 'Issue origin'
    },
    CUSTOMER_EFFECT: {
        id: 'ase.ticket.label.customerEffect',
        defaultMessage: 'Customer effect'
    },
    OPERATION_NUMBER: {
        id: 'ase.ticket.label.operationNumber',
        defaultMessage: 'Operation number'
    },
    PART_REFERENCE_ASSOCIATED: {
        id: 'ase.ticket.label.partReferenceAssociatedToTheIssue',
        defaultMessage: 'Part reference associated to the issue'
    },
    FIC_CIR_ASSOCIATED: {
        id: 'ase.ticket.label.ficCirAssociated',
        defaultMessage: 'FIC/CIR associated'
    },
    VEHICLE_DETAIL_SECTION: {
        id: 'ase.ticket.header.vehicleDetail',
        defaultMessage: 'Vehicle Details'
    },
    BVM_CONNECTION: {
        id: 'ase.ticket.label.bvmConnection',
        defaultMessage: 'BVM connection'
    },
    VIN: {
        id: 'ase.ticket.label.vinNumber',
        defaultMessage: 'VIN'
    },
    MANUFACTURE_DATE: {
        id: 'ase.ticket.label.manufactureDate',
        defaultMessage: 'Manufacturing Date'
    },
    VEHICLE_FAMILY: {
        id: 'ase.ticket.label.vehicleFamily',
        defaultMessage: 'Vehicle Family'
    },
    VEHICLE_MODEL: {
        id: 'ase.ticket.label.vehicleModel',
        defaultMessage: 'Car Model'
    },
    REGISTRATION_NUMBER: {
        id: 'ase.ticket.label.registrationNumber',
        defaultMessage: 'Registration number'
    },
    MESSAGES_SECTION: {
        id: 'ase.ticket.header.messages',
        defaultMessage: 'Messages (Answers to dealer)'
    },
    MESSAGES_HISTORY: {
        id: 'ase.ticket.label.messagesHistory',
        defaultMessage: 'History of Messages'
    },
    MESSAGE: {
        id: 'ase.ticket.label.dialogBox',
        defaultMessage: 'DialogBox'
    },
    ATTACHMENTS_SECTION: {
        id: 'ase.ticket.header.attachments',
        defaultMessage: 'Attachments'
    },
    DEALER_ATTACHMENTS: {
        id: 'ase.ticket.label.dealerAttachments',
        defaultMessage: 'Dealer Attachments'
    },
    EDITOR_ATTACHMENTS: {
        id: 'ase.ticket.label.editorAttachments',
        defaultMessage: 'Editor Attachments'
    },
    DOCUMENTATION_UPDATE_SECTION: {
        id: 'ase.ticket.header.documentationUpdate',
        defaultMessage: 'Documentation Update'
    },
    DOC_UPDATE_NEEDED: {
        id: 'ase.ticket.label.docUpdateNeeded',
        defaultMessage: 'Documentation update needed?'
    },
    CUSTOMER_RISK: {
        id: 'ase.ticket.label.customerRisk',
        defaultMessage: 'Customer risk'
    },
    ESTIMATED_DATE_OF_MODIFICATION: {
        id: 'ase.ticket.label.estimatedDateOfModification',
        defaultMessage: 'Estimated Date Of Modification'
    },
    // editor's motes section
    EDITORS_NOTES_SECTION_AS: {
        id: 'ase.ticket.header.editorsNotes',
        defaultMessage: 'Internal Notes'
    },
    EDITORS_NOTES_HISTORY_AS: {
        id: 'ase.ticket.header.editorsNotesHistory',
        defaultMessage: 'History of Internal Notes'
    },
    EDITORS_NOTES_AS: {
        id: 'ase.ticket.label.editorsNotes',
        defaultMessage: 'Note'
    },
    DELETE_ATTACHMENT: {
        id: 'ase.internalTicket.label.deleteAttachments',
        defaultMessage: 'Delete'
    },
    CAR_MODEL: {
        id: 'ase.internalTicket.label.carModel',
        defaultMessage: 'Car Model'
    },
    REQUESTOR_INFORMATION_SECTION: {
        id: 'ase.ticket.header.requestorInformationSection',
        defaultMessage: 'Requestor Information'
    },
});

export default messages;
