import React, {Component} from 'react';
import {connect} from 'react-redux';
import get from 'get-value';
import Loader from '../../Loader';
import {
    EASY_BASKET_FORM,
    MR_TM_ASE_FORM,
} from '../../../constants/formConstants';
import PropTypes from 'prop-types';
import {moduleRoles} from '../../../utils/roles';
import {formatTicketNumber} from '../../../utils/utils';
import {editRights} from '../../../constants/aseTicketRights';
import {FormattedMessage} from 'react-intl';
import {initLoadedAseTicket} from '../Utils';
import {editReqFields} from '../../../constants/aseRequiredFields';
import {fetchAdminClientIds} from '../../../actions/admin/actionAdminClientIds';
import formMessages from '../../../intl/afterSales/formMessages';
import PageHeader from '../../common/PageHeader';
import ValidFormattedMessage from '../../common/ValidFormattedMessage';
import formTypeMessages from '../../../intl/common/formTypeMessages';
import checkRoles from '../../common/roleChecker/RoleChecker';
import {resetAseTicket} from '../../../actions/ase/actionAseTicket';
import MethodRepairLaborTimeMasterForm from './forms/MethodRepairLaborTimeMasterForm';
import EasyBasketMasterForm from "./forms/EasyBasketMasterForm";

class EditLoadTicketDataPage extends Component {
    componentDidMount() {
        const {ticketData} = this.props;
        this.props.fetchAdminClientIds(get(ticketData, 'ticket.group'), get(ticketData, 'ticket.dealerNumber'));
    }

    render() {
        const {
            ticketData,
            loggedUser,
            clientIds,
            handleSubmit,
            formType,
        } = this.props;

        if (clientIds.isLoading) {
            return <Loader/>;
        }

        const isEditor = moduleRoles.isEditorAse(loggedUser) || moduleRoles.isSGEditorAse(loggedUser);
        const requiredFields = editReqFields[editReqFields[ticketData.ticket.group] ? ticketData.ticket.group : 'DEFAULT'][isEditor ? 'EDITOR' : 'DEALER'][formType];
        const initializationValue = initLoadedAseTicket(ticketData);

        return (
            <div id="ticketPrint" className="container-fluid">
                <PageHeader title={
                    <>
                        <FormattedMessage {...formMessages.TICKET}/>{' '}
                        {formatTicketNumber(ticketData.ticket.pfxNumber, ticketData.ticket.sfxNumber)}{'-'}
                        <ValidFormattedMessage message={formType} intlMessages={formTypeMessages}/>
                    </>
                }/>
                <div>
                    {formType === MR_TM_ASE_FORM &&
                    <MethodRepairLaborTimeMasterForm form={formType}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  userRoles={this.props.loggedUser}
                                                  enableReinitialize={true}
                                                  handleSubmit={handleSubmit}
                                                  reqFields={requiredFields}
                                                  editMode
                    />
                    }
                    {formType === EASY_BASKET_FORM &&
                    <EasyBasketMasterForm form={formType}
                                                  initialValues={initializationValue}
                                                  userRights={editRights[isEditor ? 'EDITOR' : 'DEALER'][ticketData.ticket.status]}
                                                  userRoles={this.props.loggedUser}
                                                  enableReinitialize={true}
                                                  handleSubmit={handleSubmit}
                                                  reqFields={requiredFields}
                                                  editMode
                    />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: state.profile.userDetail.roles,
    ticketData: state.aseTicket,
    clientIds: state.adminClientIds,
});

EditLoadTicketDataPage.propTypes = {
    location: PropTypes.object,
    ticketData: PropTypes.object.isRequired,
    fetchAdminClientIds: PropTypes.func.isRequired,
};

export default checkRoles(connect(mapStateToProps, {
    fetchAdminClientIds,
    resetAseTicket,
})(EditLoadTicketDataPage), ['AS_DEALER', 'AS_EDITOR', 'AS_SG_EDITOR']);

