import React from 'react';
import {formValueSelector, getFormInitialValues, reduxForm} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {PrimaryButton} from '../../../../common/Button';
import buttonMessages from '../../../../../intl/common/buttonMessages';
import {
    BUTTON_LABEL_ADD_REPLY_COMMENTS,
    BUTTON_LABEL_CLOSE,
    BUTTON_LABEL_FORWARD_TO_DEALER,
    BUTTON_LABEL_FORWARD_TO_NPDC,
    BUTTON_LABEL_REOPEN,
    BUTTON_LABEL_TAKE_BACK,
    FORM_BUTTON_CLOSE,
    FORM_BUTTON_FORWARD_TO_DEALER,
    FORM_BUTTON_FORWARD_TO_NPDC,
    FORM_BUTTON_MARK_AS_READ,
    FORM_BUTTON_REOPEN,
    FORM_BUTTON_SAVE,
    FORM_BUTTON_TAKE_BACK,
    ASE_TICKET_STATUS_IN_PROGRESS_DEALER,
    ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY,
    ASE_TICKET_STATUS_READ_BY_SUBSIDIARY,
    ASE_TICKET_STATUS_REOPENED,
    ASE_TICKET_STATUS_CLOSED,
    FORM_BUTTON_ESCALATE,
    FORM_BUTTON_ANSWER,
    BUTTON_LABEL_ESCALATE,
    BUTTON_LABEL_ANSWER,
    FORM_BUTTON_CREATE_INTERNAL_TICKET,
    BUTTON_LABEL_CREATE_INTERNAL_TICKET,
    ASE_TICKET_STATUS_TRANSFERRED,
    FORM_BUTTON_EDIT,
    FORM_BUTTON_SG_TAKE_BACK,
    BUTTON_LABEL_SAVE_AND_SEND,
    BUTTON_LABEL_SAVE,
    FORM_STATUS_HISTORY_SECTION,
    MR_TM_ASE_FORM
} from '../../../../../constants/formConstants';
import {RequiredSpan} from '../../../../common/StyledComponents';
import {withRouter} from 'react-router-dom';
import get from 'get-value';
import ValidFormattedMessage from '../../../../common/ValidFormattedMessage';
import {throttle} from 'throttle-debounce';
import commonMessages from '../../../../../intl/common/commonMessages';
import {moduleRoles} from '../../../../../utils/roles';
import {domains} from '../../../../../constants/Utils';
import {editAseListButtonVisibility} from '../../../../../constants/aseTicketRights';
import {submitBy} from '../../../../../actions/ase/actionAseTicket';

const SubmitButtons = (props) => {
    const {change, handleSubmit, submitting, initialValues, readOnly, userRights, dirty, roles, dealerNumber, escalationSGs, answeringSGs, canForwardToDealerFromSG, ticketSG, editMode, form, ticket, ticketHistory = []} = props;

    const sgPermissions = get(roles, `${domains.ASE}.sgEditor.sgPermission`, {default: []});

    const getTakeBackSgList = () => {
        return sgPermissions.filter(sg => sg.isActive).map(item => ({key: item.id, value: item.name})).filter(sg => ticketHistory.some(historySg => get(historySg, "toSolutionGroup.id") === sg.key))
    }

    const filteredAnsweringSGs = (answeringSGs || []).filter(sg => ticketHistory.some(historySg => get(historySg, "toSolutionGroup.id") === sg.id));
    const [selectedGroupForEscalate, setSelectedGroupForEscalate] = React.useState(escalationSGs && escalationSGs.length && escalationSGs[0].id);
    const [selectedGroupForAnswer, setSelectedGroupForAnswer] = React.useState(filteredAnsweringSGs && filteredAnsweringSGs.length && filteredAnsweringSGs[0].id);
    const [selectedTakeBackGroup, setSelectedTakeBackGroup] = React.useState(sgPermissions && ticketHistory && sgPermissions.length && ticketHistory.length && getTakeBackSgList().length && getTakeBackSgList()[0].key);

    const ticketStatus = get(initialValues, 'ticketSection.status');
    const statusClosed = ticketStatus === ASE_TICKET_STATUS_CLOSED;
    const ticketGroup = get(ticket, 'group');
    const defaultSG = ticketGroup ? moduleRoles.isSGDefaultEditorForGroup(roles, domains.ASE, ticketGroup) : false;

    const hasSomeEscalationSG = Array.isArray(escalationSGs) && escalationSGs.length;

    const canForwardToDealer = typeof canForwardToDealerFromSG === 'boolean' ? canForwardToDealerFromSG : userRights.canForwardToDealer;
    const canClose = typeof canForwardToDealerFromSG === 'boolean' ? (canForwardToDealerFromSG && userRights.canClose) : userRights.canClose;
    const canForwardBackToSubs = userRights.canForwardBackToSubs
    const canReopen = moduleRoles.isSGEditorAse(roles) ? (!!defaultSG && userRights.canReopen) : userRights.canReopen;
    const canEscalateToSG = userRights.canEscalate && hasSomeEscalationSG;
    const canAnswerToSG = userRights.canAnswer && filteredAnsweringSGs && !!filteredAnsweringSGs.length;
    const canForwardToSubs =  userRights.canForwardToSubs && !(moduleRoles.isDealerAse(roles) && ticketSG);
    const canCreateInternalTicket = editMode && moduleRoles.isSGEditorAse(roles) && ticketSG && form === MR_TM_ASE_FORM;

    const renderBackButton = () => {
        return (
            <PrimaryButton onClick={(e) => {
                e.preventDefault();
                props.history.goBack();
            }} className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.BACK}/>
            </PrimaryButton>
        )
    };

    const renderEditButton = () => {
        return (
            <PrimaryButton onClick={
                handleSubmit(values =>
                    props.onSubmit({
                        submitBy: FORM_BUTTON_EDIT,
                    }))
            } className="btn mr-2 float-right">
                <FormattedMessage {...buttonMessages.EDIT}/>
            </PrimaryButton>
        )
    };

    const renderButton = (button, message, changeToStatus, optionalChangeToSolutionGroup) => {
        return (
            <PrimaryButton onClick={throttle(1000, async (e) => {
                e.preventDefault();
                // Unfortunately haven't found any more elegant way to omit validation on MARK_AS_READ button click, this value is checked in REQUIRED validation
                if (button === FORM_BUTTON_MARK_AS_READ) {
                    change('omitValidation', true);
                    await (interval => new Promise(resolve => setTimeout(resolve, interval)))(75);
                }

                await props.submitBy({submittedBy: button})

                handleSubmit(values =>
                    props.onSubmit({
                        newValues: {
                            ...values,
                            ticketSection: {
                                ...values.ticketSection,
                                status: changeToStatus,
                                ...(button === FORM_BUTTON_ANSWER) && {
                                    solutionGroup: {
                                        id: selectedGroupForAnswer,
                                        name: answeringSGs.find(item => selectedGroupForAnswer === item.id).name
                                    }
                                },
                                ...(button === FORM_BUTTON_ESCALATE) && {
                                    solutionGroup: {
                                        id: selectedGroupForEscalate,
                                        name: escalationSGs.find(item => selectedGroupForEscalate === item.id).name
                                    }
                                },
                                ...(button === FORM_BUTTON_SG_TAKE_BACK) && {
                                    solutionGroup: {
                                        id: optionalChangeToSolutionGroup.id,
                                        name: optionalChangeToSolutionGroup.name
                                    }
                                },
                            }
                        },
                        oldValues: initialValues,
                        submitBy: button,
                    }))()
            })
            } className="btn mr-2 float-right" disabled={submitting || (!dirty && button === FORM_BUTTON_SAVE)}>
                <ValidFormattedMessage message={message} intlMessages={buttonMessages}/>
            </PrimaryButton>
        )
    };

    const renderSelectButton = (button, message, changeToStatus, availableOptions, value, setValue) => {
        const moveLeft = {marginLeft: -10, marginRight: 10};
        const selectedSg = {id: value, name: get(availableOptions.find(sg => sg.key === value), "value")}
        return (
            <>
                <div className="input-group-prepend">
                    {renderButton(button, message, changeToStatus, selectedSg)}
                </div>
                <select className="custom-select" value={value} onChange={e => setValue(e.target.value)} style={moveLeft}>
                    {availableOptions.map((item) => <option key={item.key} value={item.key}>{item.value}</option>)}
                </select>
            </>
        )
    };

    const renderEscalateButton = () => {
        const value = selectedGroupForEscalate;
        const setValue = setSelectedGroupForEscalate;
        const availableOptions = escalationSGs.map(item => ({key: item.id, value: item.name}));
        return renderSelectButton(FORM_BUTTON_ESCALATE, BUTTON_LABEL_ESCALATE, ASE_TICKET_STATUS_TRANSFERRED, availableOptions, value, setValue);
    };

    const renderAnswerButton = () => {
        const value = selectedGroupForAnswer;
        const setValue = setSelectedGroupForAnswer;
        const availableOptions = filteredAnsweringSGs.map(item => ({key: item.id, value: item.name}));
        return renderSelectButton(FORM_BUTTON_ANSWER, BUTTON_LABEL_ANSWER, ASE_TICKET_STATUS_TRANSFERRED, availableOptions, value, setValue);
    };

    const renderTakeBackButton = (button, message, changeToStatus) => {
        const value = selectedTakeBackGroup;
        const setValue = setSelectedTakeBackGroup;
        const availableOptions = getTakeBackSgList()
        return renderSelectButton(button, message, changeToStatus, availableOptions, value, setValue);
    };

    const renderReopenButton = () => {
        return renderButton(FORM_BUTTON_REOPEN, BUTTON_LABEL_REOPEN, ASE_TICKET_STATUS_REOPENED);
    };

    const renderCreateInternalTicketButton = (status) => {
        return renderButton(FORM_BUTTON_CREATE_INTERNAL_TICKET, BUTTON_LABEL_CREATE_INTERNAL_TICKET, status);
    };

    const ticketInNonEditableState = () => {
        const buttonVisibility = editAseListButtonVisibility[moduleRoles.isDealerAse(roles) ? 'DEALER' : 'EDITOR'];
        return !buttonVisibility.includes(ticketStatus);
    };

    if (readOnly) {
        const ticketBelongsToDealer = moduleRoles.isDealerAse(roles) && dealerNumber === initialValues.ticketSection.dealerNumber;
        const isEditor = moduleRoles.isEditor(roles) || moduleRoles.isSGEditorAse(roles);
        const editorGroups = moduleRoles.isEditorAse(roles) ? moduleRoles.getEditorGroups(domains.ASE, roles).map(e => e.group) : false;
        let canSwitchToEdit = !statusClosed;
        const canSgTakeBack = !statusClosed && ticketSG && get(ticketSG, 'id') !== get(defaultSG, 'id') && !moduleRoles.isSGEditorAseInSG(roles, get(ticketSG, 'id')) && sgPermissions.length > 0;

        if (ticketInNonEditableState()) {
            canSwitchToEdit = false;
        } else if (editorGroups && !sgPermissions && !(editorGroups || []).includes(ticketGroup)){
            canSwitchToEdit = false;
        } else if (moduleRoles.isDealerAse(roles) && (!ticketBelongsToDealer || ticketSG)){
            canSwitchToEdit = false;
        } else if (moduleRoles.isSGEditorAse(roles)) {
            if (ticketSG && !moduleRoles.isSGEditorAseInSG(roles, get(ticketSG, 'id', {default:''}))) {
                // ticket is assigned on group which user is not member of
                canSwitchToEdit = false;
            } else if (!ticketSG && !defaultSG) {
                // ticket can be switched to edit
                canSwitchToEdit = false;
            }
        }

        return (
            <div className="d-flex justify-content-end mt-3">
                <form>
                    <div className={canSgTakeBack ? "input-group" : "form-group"}>
                        {renderBackButton()}
                        {canSwitchToEdit && renderEditButton()}
                        {canSgTakeBack && renderTakeBackButton(FORM_BUTTON_SG_TAKE_BACK, BUTTON_LABEL_TAKE_BACK, ASE_TICKET_STATUS_TRANSFERRED)}
                        {(ticketBelongsToDealer || isEditor) && canReopen && statusClosed && renderReopenButton()}
                    </div>
                </form>
            </div>
        )
    }
    return (
        <div className="d-flex justify-content-between mt-3">
            <div><RequiredSpan>{'*\u00A0'}</RequiredSpan><FormattedMessage {...commonMessages.IS_MANDATORY}/></div>
            <form>
                <div className="input-group">
                    {get(initialValues, 'ticketSection.uuid') && renderBackButton()}
                    {canEscalateToSG && renderEscalateButton()}
                    {canAnswerToSG && renderAnswerButton()}
                    {canReopen && statusClosed && renderReopenButton()}
                    {canForwardBackToSubs && renderTakeBackButton(FORM_BUTTON_SG_TAKE_BACK, BUTTON_LABEL_TAKE_BACK, ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY)}
                    {canClose && renderButton(FORM_BUTTON_CLOSE, BUTTON_LABEL_CLOSE, ASE_TICKET_STATUS_CLOSED)}
                    {userRights.canSave && renderButton(FORM_BUTTON_SAVE, editMode ? BUTTON_LABEL_SAVE : BUTTON_LABEL_SAVE_AND_SEND, ticketStatus)}
                    {canForwardToSubs && renderButton(FORM_BUTTON_FORWARD_TO_NPDC, BUTTON_LABEL_FORWARD_TO_NPDC, ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY)}
                    {canForwardToDealer && renderButton(FORM_BUTTON_FORWARD_TO_DEALER, BUTTON_LABEL_FORWARD_TO_DEALER, ASE_TICKET_STATUS_IN_PROGRESS_DEALER)}
                    {userRights.canChangeToReadBySubs && renderButton(FORM_BUTTON_MARK_AS_READ, BUTTON_LABEL_ADD_REPLY_COMMENTS, ASE_TICKET_STATUS_READ_BY_SUBSIDIARY)}
                    {canCreateInternalTicket && renderCreateInternalTicketButton(ticketStatus)}
                </div>
            </form>
        </div>
    )
}

SubmitButtons.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    readOnly: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
    initialValues: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    userRights: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
    ticketSG: PropTypes.any,
    ticket: PropTypes.any,
};

function getSolutionGroups(state, schemaType) {
    if (!state.solutionGroupInfo || !state.solutionGroupInfo[schemaType]) {
        return undefined;
    }
    const formType = get(state, 'ticket.formType');
    return state.solutionGroupInfo[schemaType][formType]
}

const mapStateToProps = (state, initialProps) => ({
    ticketHistory: formValueSelector(initialProps.form)(state, `${FORM_STATUS_HISTORY_SECTION}`),
    initialValues: getFormInitialValues(initialProps.form)(state),
    roles: state.profile.userDetail.roles,
    escalationSGs: getSolutionGroups(state.aseTicket, 'escalationSchemas'),
    answeringSGs: getSolutionGroups(state.aseTicket, 'answeringSchemas'),
    canForwardToDealerFromSG: get(state, 'aseTicket.solutionGroupInfo.canForwardToDealer'),
    ticketSG: get(state, 'aseTicket.ticket.solutionGroup'),
    ticket: get(state, 'aseTicket.ticket'),
    dealerNumber: state.profile.userDetail.dealerNumber,
});

export default reduxForm({})(withRouter(connect(mapStateToProps, {
    submitBy,
})(SubmitButtons)));
