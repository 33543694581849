import {
    CHECKBOX_LABEL_BVM_CONNECTION,
    FORM_CHECKBOX_BVM_CONNECTION,
    FORM_FIELD_ATTACHMENTS,
    FORM_FIELD_CAR_MODEL,
    FORM_FIELD_CLIENT_ID,
    FORM_FIELD_CREATION_DATE,
    FORM_FIELD_DEALER_NAME,
    FORM_FIELD_DEALER_NO,
    FORM_FIELD_DOC_UPDATE_NEEDED,
    FORM_FIELD_EMAIL,
    FORM_FIELD_ENGINE_SERIAL_NUMBER,
    FORM_FIELD_ENGINE_TYPE,
    FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_FIELD_FIC_CIR,
    FORM_FIELD_GEARBOX_SERIAL_NUMBER,
    FORM_FIELD_GEARBOX_TYPE,
    FORM_FIELD_ISSUE_ORIGIN,
    FORM_FIELD_MANUFACTURE_DATE,
    FORM_FIELD_MESSAGE,
    FORM_FIELD_NEW_ATTACHMENTS,
    FORM_FIELD_NOD_ID,
    FORM_FIELD_PART_REFERENCE_ASSOCIATED,
    FORM_FIELD_PFX,
    FORM_FIELD_PROBLEM_DESCRIPTION,
    FORM_FIELD_REGION,
    FORM_FIELD_REGISTRATION_NO,
    FORM_FIELD_DOCUMENT_ID,
    FORM_FIELD_REQUEST_ROLE,
    FORM_FIELD_ROOT_CAUSE,
    FORM_FIELD_SFX,
    FORM_FIELD_TELEPHONE_NO,
    FORM_FIELD_TEMP_SOLUTION,
    FORM_FIELD_UPDATE_FILE_NUMBER,
    FORM_FIELD_VIN,
    FORM_LABEL_ATTACHMENTS,
    FORM_LABEL_CLIENT_ID,
    FORM_LABEL_CREATION_DATE,
    FORM_LABEL_DEALER_NAME,
    FORM_LABEL_DEALER_NO,
    FORM_LABEL_DOC_UPDATE_NEEDED,
    FORM_LABEL_EMAIL,
    FORM_LABEL_ENGINE_SERIAL_NUMBER,
    FORM_LABEL_ENGINE_TYPE,
    FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_LABEL_FIC_CIR,
    FORM_LABEL_GEARBOX_SERIAL_NUMBER,
    FORM_LABEL_GEARBOX_TYPE,
    FORM_LABEL_ISSUE_ORIGIN,
    FORM_LABEL_MANUFACTURE_DATE,
    FORM_LABEL_MESSAGE,
    FORM_LABEL_NEW_ATTACHMENTS,
    FORM_LABEL_NOD_ID,
    FORM_LABEL_PART_REFERENCE_ASSOCIATED,
    FORM_LABEL_PROBLEM_DESCRIPTION,
    FORM_LABEL_REGION,
    FORM_LABEL_REGISTRATION_NO,
    FORM_LABEL_DOCUMENT_ID,
    FORM_LABEL_REQUESTOR_ROLE,
    FORM_LABEL_ROOT_CAUSE,
    FORM_LABEL_TELEPHONE_NO,
    FORM_LABEL_TEMP_SOLUTION,
    FORM_LABEL_TICKET_NUMBER,
    FORM_LABEL_UPDATE_FILE_NUMBER,
    FORM_LABEL_VEHICLE_MODEL,
    FORM_LABEL_VIN,
    MR_TM_ASE_FORM,
    FORM_LABEL_CUSTOMER_EFFECT,
    FORM_LABEL_CUSTOMER_RISK,
    FORM_FIELD_CUSTOMER_RISK,
    FORM_FIELD_CUSTOMER_EFFECT,
    FORM_FIELD_SOLUTION_GROUP,
    FORM_LABEL_SOLUTION_GROUP,
    FORM_FIELD_PRIORITY_CASES,
    FORM_LABEL_PRIORITY_CASES,
    FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY,
    FORM_LABEL_VEHICLE_COVERED_BY_WARRANTY,
    FORM_FIELD_PRIORITY_TREATMENT,
    FORM_LABEL_PRIORITY_TREATMENT,
    EASY_BASKET_FORM,
    FORM_FIELD_OPERATION_NO, FORM_LABEL_OPERATION_NO,
} from './formConstants';

export const aseFieldLabelMapping = {
    [FORM_FIELD_CLIENT_ID]: FORM_LABEL_CLIENT_ID,
    [FORM_FIELD_DEALER_NAME]: FORM_LABEL_DEALER_NAME,
    [FORM_FIELD_DEALER_NO]: FORM_LABEL_DEALER_NO,
    [FORM_FIELD_REGION]: FORM_LABEL_REGION,
    [FORM_FIELD_EMAIL]: FORM_LABEL_EMAIL,
    [FORM_FIELD_TELEPHONE_NO]: FORM_LABEL_TELEPHONE_NO,
    [FORM_FIELD_CREATION_DATE]: FORM_LABEL_CREATION_DATE,
    [FORM_FIELD_MESSAGE]: FORM_LABEL_MESSAGE,
    [FORM_FIELD_ENGINE_TYPE]: FORM_LABEL_ENGINE_TYPE,
    [FORM_FIELD_ENGINE_SERIAL_NUMBER]: FORM_LABEL_ENGINE_SERIAL_NUMBER,
    [FORM_FIELD_GEARBOX_TYPE]: FORM_LABEL_GEARBOX_TYPE,
    [FORM_FIELD_GEARBOX_SERIAL_NUMBER]: FORM_LABEL_GEARBOX_SERIAL_NUMBER,
    [FORM_FIELD_PROBLEM_DESCRIPTION]: FORM_LABEL_PROBLEM_DESCRIPTION,
    [FORM_FIELD_ATTACHMENTS]: FORM_LABEL_ATTACHMENTS,
    [FORM_FIELD_NEW_ATTACHMENTS]: FORM_LABEL_NEW_ATTACHMENTS,
    [FORM_FIELD_VIN]: FORM_LABEL_VIN,
    [FORM_FIELD_MANUFACTURE_DATE]: FORM_LABEL_MANUFACTURE_DATE,
    [FORM_FIELD_REGISTRATION_NO]: FORM_LABEL_REGISTRATION_NO,
    [FORM_FIELD_CAR_MODEL]: FORM_LABEL_VEHICLE_MODEL,
    [FORM_CHECKBOX_BVM_CONNECTION]: CHECKBOX_LABEL_BVM_CONNECTION,
    [MR_TM_ASE_FORM]: MR_TM_ASE_FORM,
    [EASY_BASKET_FORM]: EASY_BASKET_FORM,
    [FORM_FIELD_OPERATION_NO]: FORM_LABEL_OPERATION_NO,

    [FORM_FIELD_DOC_UPDATE_NEEDED]: FORM_LABEL_DOC_UPDATE_NEEDED,
    [FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION]: FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION,
    [FORM_FIELD_ROOT_CAUSE]: FORM_LABEL_ROOT_CAUSE,
    [FORM_FIELD_UPDATE_FILE_NUMBER]: FORM_LABEL_UPDATE_FILE_NUMBER,
    // [FORM_FIELD_LUP_QS_ASSOCIATED]: FORM_LABEL_LUP_QS_ASSOCIATED,
    [FORM_FIELD_CUSTOMER_EFFECT]: FORM_LABEL_CUSTOMER_EFFECT,
    [FORM_FIELD_CUSTOMER_RISK]: FORM_LABEL_CUSTOMER_RISK,

    //search engine
    [FORM_FIELD_PFX]: FORM_LABEL_TICKET_NUMBER,
    [FORM_FIELD_SFX]: FORM_LABEL_TICKET_NUMBER,

    //ASE Specific
    [FORM_FIELD_ISSUE_ORIGIN]: FORM_LABEL_ISSUE_ORIGIN,
    [FORM_FIELD_NOD_ID]: FORM_LABEL_NOD_ID,
    [FORM_FIELD_PROBLEM_DESCRIPTION]: FORM_LABEL_PROBLEM_DESCRIPTION,
    [FORM_FIELD_TEMP_SOLUTION]: FORM_LABEL_TEMP_SOLUTION,
    [FORM_FIELD_DOCUMENT_ID]: FORM_LABEL_DOCUMENT_ID,
    [FORM_FIELD_FIC_CIR]: FORM_LABEL_FIC_CIR,
    [FORM_FIELD_PART_REFERENCE_ASSOCIATED]: FORM_LABEL_PART_REFERENCE_ASSOCIATED,
    [FORM_FIELD_REQUEST_ROLE]: FORM_LABEL_REQUESTOR_ROLE,
    [FORM_FIELD_SOLUTION_GROUP]: FORM_LABEL_SOLUTION_GROUP,
    [FORM_FIELD_PRIORITY_CASES]: FORM_LABEL_PRIORITY_CASES,
    [FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY]: FORM_LABEL_VEHICLE_COVERED_BY_WARRANTY,
    [FORM_FIELD_PRIORITY_TREATMENT]: FORM_LABEL_PRIORITY_TREATMENT,
};
