

//AS Ticket statuses
export const ASE_TICKET_STATUS_NEW = 'NEW';
export const ASE_TICKET_STATUS_IN_PROGRESS_DEALER = 'WAIT_DEALER';
export const ASE_TICKET_STATUS_IN_PROGRESS_SUBSIDIARY = 'WAIT_SUBSIDIARY';
export const ASE_TICKET_STATUS_READ_BY_SUBSIDIARY = 'READ_BY_SUBSIDIARY';
export const ASE_TICKET_STATUS_REOPENED = 'REOPEN';
export const ASE_TICKET_STATUS_CLOSED = 'CLOSED';
export const ASE_TICKET_STATUS_TRANSFERRED = 'TRANSFERRED';
// only for AS internal ticket
export const ASE_INTERNAL_TICKET_STATUS_NEW = 'NEW';
export const ASE_INTERNAL_TICKET_STATUS_CLOSED = 'CLOSED';
export const ASE_INTERNAL_TICKET_STATUS_TRANSFERRED = 'TRANSFERRED';
export const ASE_INTERNAL_TICKET_STATUS_ANSWERED = 'ANSWERED';
export const ASE_INTERNAL_TICKET_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const ASE_INTERNAL_TICKET_STATUS_REOPENED = 'REOPENED';


//AS form types
export const MR_TM_ASE_FORM = 'MR_TM_ASE';
export const EASY_BASKET_FORM = 'EASY_BASKET';
export const ASE_INTERNAL_TICKET_FORM = 'ASE_INTERNAL_TICKET';

//SP form fields names mappings
//Dealer information section
export const FORM_TICKET_SECTION = 'ticketSection';
export const FORM_FIELD_CLIENT_ID = 'clientId';
export const FORM_FIELD_DEALER_NAME = 'dealerName';
export const FORM_FIELD_DEALER_NO = 'dealerNumber';
export const FORM_FIELD_REGION = 'region';
export const FORM_FIELD_NAME_TD_SEARCH = 'creatorName';
export const FORM_FIELD_EMAIL = 'email';
export const FORM_FIELD_TELEPHONE_NO = 'telNo';
export const FORM_FIELD_CREATION_DATE = 'created';
export const FORM_FIELD_SFX = 'sfxNumber';
export const FORM_FIELD_PFX = 'pfxNumber';

//Dealer information section
export const FORM_DEALER_SECTION = 'dealerSection';


//Notes section
//
export const FORM_MESSAGES_SECTION = 'messagesSection';
export const FORM_FIELD_MESSAGE = 'newMessage';
export const FORM_FIELD_MESSAGE_OPTION = 'messageOption';    //chybí v map

//Supplementary information section
export const FORM_FIELD_ENGINE_TYPE = 'engineType';
export const FORM_FIELD_ENGINE_SERIAL_NUMBER = 'engineNumber';
export const FORM_FIELD_GEARBOX_TYPE = 'gearboxType';
export const FORM_FIELD_GEARBOX_SERIAL_NUMBER = 'gearboxNumber';
export const FORM_FIELD_MORE_DETAILS = 'moreDetails';

//Problem description section
export const FORM_PROBLEM_DESCRIPTION_SECTION = 'problemDescription';
export const FORM_FIELD_NOD_ID = 'nodId';
export const FORM_FIELD_PART_REFERENCE_ASSOCIATED = 'partAssociatedNumber';
export const FORM_FIELD_FIC_CIR = 'ficCirAssociated';
export const FORM_FIELD_ISSUE_ORIGIN = 'issueOrigin';
export const FORM_FIELD_CUSTOMER_EFFECT = 'customerEffect';
export const FORM_FIELD_DOCUMENT_ID = 'documentId';
export const FORM_FIELD_OPERATION_NO = 'operationNumber';
export const FORM_FIELD_VEHICLE_COVERED_BY_WARRANTY = 'vehicleCoveredByWarranty';
export const FORM_FIELD_PROBLEM_DESCRIPTION = 'problemDesc';
export const FORM_FIELD_TEMPORARY_SOLUTION = 'temporarySolution';
export const FORM_FIELD_TEMP_SOLUTION = 'temporarySolution';

//Documentation update section
export const DOCUMENTATION_UPDATE_SECTION = 'documentationUpdate';
export const FORM_FIELD_DOC_UPDATE_NEEDED = 'docUpdateNeeded';
export const FORM_FIELD_UPDATE_FILE_NUMBER = 'updateFileNumber';
export const FORM_FIELD_LUP_QS_ASSOCIATED = 'lupQsAssociated';
export const FORM_FIELD_CUSTOMER_RISK = 'customerRisk';
export const FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION = 'estimatedDateOfModification';
export const FORM_FIELD_ROOT_CAUSE = 'rootCause';

//Store check results section
//Attachments section
export const FORM_FIELD_NEW_ATTACHMENTS = 'newAttachments';
export const FORM_FIELD_ATTACHMENTS = 'attachments';
export const FORM_ATTACHMENTS_SECTION = 'attachmentsSection';

//Vehicle Detail section
export const FORM_VEHICLE_DETAIL_SECTION = 'vehicleDetail';
export const FORM_FIELD_VIN = 'vin';
export const FORM_FIELD_MANUFACTURE_DATE = 'manufactureDate';
export const FORM_FIELD_REGISTRATION_NO = 'registrationNumber';
export const FORM_FIELD_CAR_MODEL = 'carModel';

//Car courtesy reply

export const FORM_EDITORS_NOTES_SECTION_AS = 'formEditorsNotesSectionAs';
export const FORM_LABEL_EDITORS_NOTES_AS = 'EDITORS_NOTES_AS';
export const FORM_FIELD_EDITORS_NOTES_AS = 'editorsNotesAS';
export const FORM_LABEL_EDITORS_NOTES_HISTORY_AS = 'EDITORS_NOTES_HISTORY_AS';
export const FORM_FIELD_FROM = 'from';


//Request Detail section
export const FORM_FIELD_REQUESTOR_NAME = 'requestorName';
export const FORM_FIELD_REQUESTOR_IPN = 'requestorIpn';
export const FORM_FIELD_CREATOR_IPN = 'creatorIpn';
export const FORM_FIELD_SOLUTION_GROUP = 'solutionGroup';
export const FORM_FIELD_PRIORITY_TREATMENT = 'priorityTreatment';
export const FORM_FIELD_PRIORITY_CASES = 'priorityCases';
export const FORM_FIELD_NETWORK_TICKET_NUMBER = 'networkTicketNumber';
export const FORM_FIELD_INTERNAL_TICKET_NUMBER = 'ticketNumber';
export const FORM_FIELD_DEALER_TICKET_NUMBER = 'dealerTicketNumber';
export const FORM_FIELD_DEALER_FORM_TYPE = 'dealerFormType';
export const FORM_FIELD_REQUEST_STATUS = 'status';
export const FORM_FIELD_SG_REQUESTER = 'sgRequester';export const FORM_FIELD_CREATED_AT_INTERNAL_TICKET_FROM = 'from';
export const FORM_FIELD_CREATED_AT_INTERNAL_TICKET_TO = 'to';
export const FORM_FIELD_REQUEST_ROLE = 'requestorRole';

//Status history
export const FORM_STATUS_HISTORY_SECTION = 'statusHistory';

//SP form label names mappings
//Dealer information section
export const FORM_LABEL_CLIENT_ID = 'CLIENT_ID';
export const FORM_LABEL_DEALER_NAME = 'DEALER_NAME';
export const FORM_LABEL_DEALER_NO = 'DEALER_NUMBER';
export const FORM_LABEL_REGION = 'REGION';
export const FORM_LABEL_NAME = 'NAME';
export const FORM_LABEL_EMAIL = 'EMAIL';
export const FORM_LABEL_TELEPHONE_NO = 'TELEPHONE_NUMBER';
export const FORM_LABEL_CREATION_DATE = 'CREATION_DATE';
export const FORM_LABEL_TICKET_NUMBER = 'TICKET_NUMBER';


//Notes section
export const FORM_LABEL_MESSAGES_HISTORY = 'MESSAGES_HISTORY';
export const FORM_LABEL_MESSAGE = 'MESSAGE';

//Supplementary information section
export const FORM_LABEL_ENGINE_TYPE = 'ENGINE_TYPE';
export const FORM_LABEL_ENGINE_SERIAL_NUMBER = 'ENGINE_SERIAL_NUMBER';
export const FORM_LABEL_GEARBOX_TYPE = 'GEARBOX_TYPE';
export const FORM_LABEL_GEARBOX_SERIAL_NUMBER = 'GEARBOX_SERIAL_NUMBER';
export const FORM_LABEL_MORE_DETAILS = 'MORE_DETAILS';

// doc update section
export const FORM_LABEL_DOC_UPDATE_NEEDED = 'DOC_UPDATE_NEEDED';
export const FORM_LABEL_UPDATE_FILE_NUMBER = 'UPDATE_FILE_NUMBER';
export const FORM_LABEL_LUP_QS_ASSOCIATED = 'LUP_QS_ASSOCIATED';
export const FORM_LABEL_CUSTOMER_RISK = 'CUSTOMER_RISK';
export const FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION = 'ESTIMATED_DATE_OF_MODIFICATION';
export const FORM_LABEL_ROOT_CAUSE = 'ROOT_CAUSE';


// export const FORM_LABEL_QUANTITY = 'QUANTITY';
export const FORM_LABEL_NOD_ID = 'NOD_ID';
export const FORM_LABEL_DOCUMENT_ID = 'DOCUMENT_ID';
export const FORM_LABEL_OPERATION_NO = 'OPERATION_NUMBER';
export const FORM_LABEL_VEHICLE_COVERED_BY_WARRANTY = 'VEHICLE_COVERED_BY_WARRANTY';
export const FORM_LABEL_PART_REFERENCE_ASSOCIATED = 'PART_REFERENCE_ASSOCIATED';
export const FORM_LABEL_FIC_CIR = 'FIC_CIR_ASSOCIATED';
export const FORM_LABEL_ISSUE_ORIGIN = 'ISSUE_ORIGIN';
export const FORM_LABEL_CUSTOMER_EFFECT = 'CUSTOMER_EFFECT';
export const FORM_LABEL_PROBLEM_DESCRIPTION = 'PROBLEM_DESCRIPTION';
export const FORM_LABEL_TEMPORARY_SOLUTION = 'TEMPORARY_SOLUTION';
export const FORM_LABEL_TEMP_SOLUTION = 'TEMPORARY_SOLUTION';

//Attachments section
export const FORM_LABEL_ATTACHMENTS = 'ATTACHMENTS';
export const FORM_LABEL_NEW_ATTACHMENTS = 'NEW_ATTACHMENTS';

//Vehicle detail section
export const FORM_LABEL_VIN = 'VIN';
export const FORM_LABEL_MANUFACTURE_DATE = 'MANUFACTURE_DATE';
export const FORM_LABEL_REGISTRATION_NO = 'REGISTRATION_NUMBER';
export const FORM_LABEL_VEHICLE_MODEL = 'VEHICLE_MODEL';
export const FORM_LABEL_CAR_MODEL = 'CAR_MODEL';
export const FORM_LABEL_FROM = 'FROM';


//Request Detail labels
export const FORM_LABEL_REQUESTOR_NAME = 'REQUESTOR_NAME';
export const FORM_LABEL_REQUESTOR_IPN = 'REQUESTOR_IPN';
export const FORM_LABEL_PRIORITY_TREATMENT = 'PRIORITY_TREATMENT';
export const FORM_LABEL_PRIORITY_CASES = 'PRIORITY_CASES';
export const FORM_LABEL_NETWORK_TICKET_NUMBER = 'NETWORK_TICKET_NUMBER';
export const FORM_LABEL_INTERNAL_TICKET_NUMBER = 'INTERNAL_TICKET_NUMBER';
export const FORM_LABEL_REQUEST_STATUS = 'REQUEST_STATUS';
export const FORM_LABEL_SG_REQUESTER = 'SG_REQUESTER';
export const FORM_LABEL_CREATED_AT_INTERNAL_TICKET_FROM = 'CREATED_AT_INTERNAL_TICKET_FROM';
export const FORM_LABEL_CREATED_AT_INTERNAL_TICKET_TO = 'CREATED_AT_INTERNAL_TICKET_TO';
export const FORM_LABEL_DEALER_TICKET_NUMBER = 'DEALER_TICKET_NUMBER';
export const FORM_LABEL_DEALER_FORM_TYPE = 'DEALER_FORM_TYPE';
export const FORM_LABEL_REQUESTOR_ROLE = 'REQUESTOR_ROLE';


//Button mappings
export const FORM_BUTTON_CLOSE = 'closeButton';
export const FORM_BUTTON_SAVE = 'saveButton';
export const FORM_BUTTON_EDIT = 'editButton';
export const FORM_BUTTON_REOPEN = 'reopenButton';
export const FORM_BUTTON_FORWARD_TO_NPDC = 'forwardToNPDCButton';
export const FORM_BUTTON_FORWARD_TO_DEALER = 'forwardToDealerButton';
export const FORM_BUTTON_MARK_AS_READ = 'markAsReadButton';
export const FORM_BUTTON_CREATE_INTERNAL_TICKET = 'createInternalTicketButton';
export const FORM_BUTTON_ESCALATE = 'escalateButton';
export const FORM_BUTTON_ANSWER = 'answerButton';
export const FORM_BUTTON_TAKE_BACK = 'takeBackButton';
export const FORM_BUTTON_SG_TAKE_BACK = 'solutionGroupTakeBackButton';
export const FORM_CHECKBOX_BVM_CONNECTION = 'bvmLoaded';
export const FORM_BUTTON_TRANSFER_TO_REQUESTOR = 'transferToRequestorButton';

//Button label messages mapping
export const BUTTON_LABEL_SAVE = 'SAVE';
export const BUTTON_LABEL_SAVE_AND_SEND = 'SAVE_AND_SEND';
export const BUTTON_LABEL_REOPEN = 'REOPEN';
export const BUTTON_LABEL_CLOSE = 'CLOSE';
export const BUTTON_LABEL_TAKE_BACK = 'TAKE_BACK';
export const BUTTON_LABEL_ADD_REPLY_COMMENTS = 'ADD_REPLY_COMMENTS';
export const BUTTON_LABEL_CREATE_INTERNAL_TICKET = 'CREATE_INTERNAL_TICKET';
export const BUTTON_LABEL_ESCALATE = 'ESCALATE';
export const BUTTON_LABEL_ANSWER = 'ANSWER';
export const BUTTON_LABEL_FORWARD_TO_NPDC = 'FORWARD_TO_NPDC';
export const BUTTON_LABEL_FORWARD_TO_DEALER = 'FORWARD_TO_DEALER';
export const BUTTON_LABEL_EXPORT = 'EXPORT';
export const BUTTON_LABEL_DOWNLOAD = 'DOWNLOAD';
export const CHECKBOX_LABEL_BVM_CONNECTION = 'BVM_CONNECTION';
export const BUTTON_LABEL_TRANSFER_TO_REQUESTOR = 'TRANSFER_TO_REQUESTOR';

//TD Full Text Search
export const FORM_SEARCH_OPTION_SECTION = 'searchOption';
export const FORM_FIELD_FULLTEXT_SEARCH = 'fullTextSearch';
export const FORM_FIELD_INTERNATIONAL = 'international';
export const FORM_LABEL_FULLTEXT_SEARCH = 'FULLTEXT_SEARCH';
export const FORM_LABEL_INTERNATIONAL = 'INTERNATIONAL';
export const FORM_LABEL_SOLUTION_GROUP = 'SOLUTION_GROUP';
export const FORM_FIELD_DOC_SOLUTION_GROUP = 'solutionGroup';
export const FORM_LABEL_EDITOR_IPN = 'EDITOR_IPN';
export const FORM_FIELD_EDITOR_IPN = 'editorIpn';
export const FORM_FIELD_TO = 'to';
export const FORM_LABEL_TO = 'TO';

// CL FULL TEXT SEARCH
// fields
export const FORM_FIELD_STATUS = 'status';
// labels
export const FORM_LABEL_FORM_TYPE = 'FORM_TYPE';
export const FORM_LABEL_STATUS = 'STATUS';


//ASE Full Text Search
export const FORM_FIELD_FORMTYPE = 'formType';

// Solution groups
export const SOLUTION_GROUP_FORM = 'SOLUTION_GROUP_FORM';
//Sections
export const FORM_SOLUTION_GROUP_INFO_SECTION = 'solutionGroupSection';
export const FORM_SOLUTION_GROUP_COUNTRY_SECTION = 'countrySection';
export const FORM_SOLUTION_GROUP_ADMIN_SECTION = 'adminSection';
export const FORM_SOLUTION_GROUP_USER_SECTION = 'userSection';
//Fields
export const FORM_FIELD_SG_NAME = 'name'
export const FORM_FIELD_SG_ACTIVE = 'isActive'
export const FORM_FIELD_SG_DEFAULT = 'isDefault'
export const FORM_FIELD_SG_ANSWER_TO_DEALER = 'canForwardToDealer'
export const FORM_FIELD_SG_NOTIFICATIONS = 'notifications'
export const FORM_FIELD_SG_COUNTRIES = 'countries'
export const FORM_FIELD_SG_ADMINS = 'admins'
export const FORM_FIELD_SG_MANUAL_ADMIN_LIST = 'adminList'
export const FORM_FIELD_SG_USERS = 'users'
export const FORM_FIELD_SG_MANUAL_USERS_LIST = 'userList'
//Labels
export const FORM_LABEL_SG_NAME = 'SG_NAME'
export const FORM_LABEL_SG_ACTIVE = 'SG_ACTIVE'
export const FORM_LABEL_SG_DEFAULT = 'SG_DEFAULT'
export const FORM_LABEL_SG_ANSWER_TO_DEALER = 'SG_ANSWER_TO_DEALER'
export const FORM_LABEL_SG_NOTIFICATIONS = 'SG_NOTIFICATIONS'
export const FORM_LABEL_SG_COUNTRIES = 'SG_COUNTRIES'
export const FORM_LABEL_SG_ADMINS = 'SG_ADMINS'
export const FORM_LABEL_SG_USERS = 'SG_USERS'


// Escalation schema
export const ESCALATION_SCHEMA_FORM = 'ESCALATION_SCHEMA_FORM';
//Sections
export const FORM_ESCALATION_SCHEMA_INFO_SECTION = 'escalationSchemaInfoSection';
export const FORM_ESCALATION_SCHEMA_ESCALATION_SECTION = 'escalationSchemaEscalationSection';
//Fields
export const FORM_FIELD_ES_SG_NAME = 'name';
export const FORM_FIELD_ES_FORMTYPE = 'formType';
export const FORM_FIELD_ES_ESCALATION_GROUPS = 'escalationGroups';
//Labels
export const FORM_LABEL_ES_SG_NAME = 'ES_SG_NAME';
export const FORM_LABEL_ES_FORMTYPE = 'ES_FORMTYPE';
export const FORM_LABEL_ES_ESCALATION_GROUPS = 'ES_ESCALATION_GROUPS';

// Answering schema
export const ANSWERING_SCHEMA_FORM = 'ANSWERING_SCHEMA_FORM';
//Sections
export const FORM_ANSWERING_SCHEMA_INFO_SECTION = 'answeringSchemaInfoSection';
export const FORM_ANSWERING_SCHEMA_ANSWERING_SECTION = 'answeringSchemaAnsweringSection';
//Fields
export const FORM_FIELD_AS_SG_NAME = 'name';
export const FORM_FIELD_AS_FORMTYPE = 'formType';
export const FORM_FIELD_AS_ANSWERING_GROUPS = 'answeringGroups';
//Labels
export const FORM_LABEL_AS_SG_NAME = 'AS_SG_NAME';
export const FORM_LABEL_AS_FORMTYPE = 'AS_FORMTYPE';
export const FORM_LABEL_AS_ANSWERING_GROUPS = 'AS_ANSWERING_GROUPS';

