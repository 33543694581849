import React from 'react';
import styled from 'styled-components';
import {formValueSelector, change, Field} from 'redux-form'
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
    DOCUMENTATION_UPDATE_SECTION,
    FORM_FIELD_DOC_UPDATE_NEEDED,
    FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_FIELD_ROOT_CAUSE,
    FORM_LABEL_DOC_UPDATE_NEEDED,
    FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION,
    FORM_LABEL_ROOT_CAUSE,
} from '../../../../../../constants/formConstants';
import formMessages from '../../../../../../intl/afterSales/formMessages';
import DatePickerComponent from '../../../../../common/datePicker/DatePickerComponent';
import moment from 'moment';
import commonMessages from '../../../../../../intl/common/commonMessages';
import InputSelectFieldWithValidation from '../../../../../common/InputSelectFieldWithValidation';
import {easyBasketRootCauses} from '../../../../../../constants/Utils';
import rootCauseMessages from '../../../../../../intl/afterSales/rootCauseMessages';

const StyledDiv = styled.div`
  border-radius: 5px;
`;

const EasyBasketDocumentationUpdateSection = (props) => {
    const { readOnly, reqFields, userRights, docUpdateNeeded } = props;

    return (
        <>
            <h3 className="text-left mt-4 ml-4">
                <FormattedMessage {...formMessages.DOCUMENTATION_UPDATE_SECTION}/>
            </h3>
            <StyledDiv className="border border-secondary px-4 py-2 bg-white">
                <div className="form-row">
                    <div className="col-xl-4 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_DOC_UPDATE_NEEDED]}
                                                        field={FORM_FIELD_DOC_UPDATE_NEEDED}
                                                        options={['YES', 'NO']}
                                                        intlMessages={commonMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_DOC_UPDATE_NEEDED)}
                                                        isDisabled={readOnly || !userRights.canEditDocumentationUpdate}
                        />
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <InputSelectFieldWithValidation label={formMessages[FORM_LABEL_ROOT_CAUSE]}
                                                        field={FORM_FIELD_ROOT_CAUSE}
                                                        options={easyBasketRootCauses}
                                                        intlMessages={rootCauseMessages}
                                                        isRequired={reqFields.includes(FORM_FIELD_ROOT_CAUSE)}
                                                        isDisabled={readOnly || !userRights.canEditDocumentationUpdate}
                        />
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <Field component={DatePickerComponent}
                               selectsStart
                               label={formMessages[FORM_LABEL_ESTIMATED_DATE_OF_MODIFICATION]}
                               name={FORM_FIELD_ESTIMATED_DATE_OF_MODIFICATION}
                               parse={(e) => e ? moment(e).format('YYYY-MM-DD') : null}
                               isDisabled={readOnly || (docUpdateNeeded !== 'YES') || !userRights.canEditDocumentationUpdate}
                        />
                    </div>
                </div>
            </StyledDiv>
        </>
    )

}

const selector = (form, ...other) => (formValueSelector(form))(...other);
const mapStateToProps = (state, initialProps) => ({
    userRoles: state.profile.userDetail.roles,
    docUpdateNeeded: selector(initialProps.form, state, `${DOCUMENTATION_UPDATE_SECTION}.${FORM_FIELD_DOC_UPDATE_NEEDED}`),
});

const mapDispatchToProps = (dispatch, props) => ({
    change: (name, value) => dispatch(change(props.form, name, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(EasyBasketDocumentationUpdateSection);
