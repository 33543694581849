import {defineMessages} from 'react-intl';

export const messages = defineMessages({
    MISSING: {
        id: 'ase.customerEffect.option.missing',
        defaultMessage: 'Missing'
    },
    NOT_CORRECT: {
        id: 'ase.customerEffect.option.notCorrect',
        defaultMessage: 'Not correct'
    },
    WRONG_TRANSLATION: {
        id: 'ase.customerEffect.option.wrongTranslation',
        defaultMessage: 'Wrong translation'
    },
    EASY_BASKET_NOT_COMPLETED: {
        id: 'ase.customerEffect.option.easyBasketNotCompleted',
        defaultMessage: 'Easy Basket not complete'
    },
    MISSING_REFERENCE: {
        id: 'ase.customerEffect.option.missingReference',
        defaultMessage: 'Missing reference'
    },
    MISSING_LABOR_TIME: {
        id: 'ase.customerEffect.option.missingLaborTime',
        defaultMessage: 'Missing labor time'
    },
    DISPLAY_ISSUE: {
        id: 'ase.customerEffect.option.displayIssue',
        defaultMessage: 'Display issue'
    },
    OTHER: {
        id: 'ase.customerEffect.option.other',
        defaultMessage: 'Other'
    }
});

export default messages;
