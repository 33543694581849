import v4 from 'uuid/v4';
import {
    ASE_TICKET_STATUS_NEW,
    EASY_BASKET_FORM,
    FORM_EDITORS_NOTES_SECTION_AS,
    FORM_FIELD_EDITORS_NOTES_AS,
    FORM_FIELD_ISSUE_ORIGIN,
    FORM_PROBLEM_DESCRIPTION_SECTION,
} from '../../constants/formConstants';
import get from 'get-value';
import set from 'set-value';
import {diff} from 'deep-object-diff';
import {formatTicketNumber} from '../../utils/utils';
import {EASY_BASKET} from '../../constants/Utils';

export const initLoadedAseTicket = (ticketData) => {
    const {lockIpn, lockTime, ...ticketSection} = get(ticketData, 'ticket', {default: {}});
    return {
        ticketSection: {...ticketSection},
        vehicleDetail: {...ticketData.vehicleDetail},
        problemDescription: ticketData.problemDescription,
        documentationUpdate: ticketData.documentationUpdate,
        messages: ticketData.messages,
        statusHistory: ticketData.logs,
        attachmentsSection: {attachments: ticketData.attachments},
        editorsNotes: ticketData.editorsNotes,
        internalTicketReferences: ticketData.internalTicketReferences,
    };
};

export const initNewAseTicket = (userDetail, formType) => {
    if (!userDetail.dealerNumber || userDetail === '') {
        return undefined;
    }
    let data = {
        ticketSection: {
            dealerNumber: userDetail.dealerNumber,
            dealerName: userDetail.dealerName,
            email: userDetail.mail,
            group: userDetail.group,
            telNo: userDetail.telNo,
            status: ASE_TICKET_STATUS_NEW,
        },
        vehicleDetail: {
            bvmLoaded: true
        },
    };
    if (formType === EASY_BASKET_FORM) {
        data = set(data, `${FORM_PROBLEM_DESCRIPTION_SECTION}.${FORM_FIELD_ISSUE_ORIGIN}`, EASY_BASKET);
    }
    return data;
};

export const transformNewTicketForSave = (values) => {
    const data = {};
    const {...vehicleDetail} = values.vehicleDetail;


    data.ticket = {
        ...values.ticketSection,
        uuid: v4(),
    };

    data.vehicleDetail = vehicleDetail;

    if (values.problemDescription) {
        data.problemDescription = values.problemDescription;
    }

    if (get(values, 'attachmentsSection.newAttachments', {default: false}) && values.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        values.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                ...attachment,
                uuid: v4(),
            })
        })
    }
    return data;
};

export const transformUpdateAseTicketForSave = (values) => {
    const {oldValues, newValues, submitBy} = values;
    const difference = diff(oldValues, newValues);
    const data = {};
    data.submitBy = submitBy
    data.ticket = {
        ...get(difference, 'ticketSection'),
        uuid: oldValues.ticketSection.uuid,
        pfxNumber: oldValues.ticketSection.pfxNumber,
        sfxNumber: oldValues.ticketSection.sfxNumber,
        status: newValues.ticketSection.status,
    };

    const newVehicleDetail = get(difference, 'vehicleDetail');
    if (newVehicleDetail) {
        data.vehicleDetail = newVehicleDetail;
    }

    const newProblemDescription = get(difference, 'problemDescription');
    if (newProblemDescription) {
        data.problemDescription = newProblemDescription;
    }

    const newDocumentationUpdate = get(difference, 'documentationUpdate');
    if (newDocumentationUpdate) {
        data.documentationUpdate = newDocumentationUpdate;
    }

    const newMessage = get(difference, 'messagesSection.newMessage');
    if (newMessage) {
        data.message = {
            uuid: v4(),
            newMessage,
        };
    }

    const newEditorsNote = get(difference, `${FORM_EDITORS_NOTES_SECTION_AS}.${FORM_FIELD_EDITORS_NOTES_AS}`);
    if (newEditorsNote) {
        data.editorsNote = {
            uuid: v4(),
            newEditorsNote,
        };
    }

    if (get(difference, 'attachmentsSection.newAttachments', {default: false}) && difference.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        difference.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                uuid: v4(),
                ...attachment,
            })
        })
    }

    return data;
};

export const createInternalTicketAseInitData = (newValues) => {
    console.log(newValues)
    return {
        vin: get(newValues, 'vehicleDetail.vin'),
        registrationNumber: get(newValues, 'vehicleDetail.registrationNumber'),
        moreDetails: get(newValues, 'vehicleDetail.moreDetails'),

        issueOrigin: get(newValues, 'problemDescription.issueOrigin'),
        partAssociatedNumber: get(newValues, 'problemDescription.partAssociatedNumber'),
        temporarySolution: get(newValues, 'problemDescription.temporarySolution'),
        nodId: get(newValues, 'problemDescription.nodId'),
        repairMethod: get(newValues, 'problemDescription.repairMethod'),
        problemDesc: get(newValues, 'problemDescription.problemDesc'),

        ticketReference: {
            uuid: get(newValues, 'ticketSection.uuid'),
            pfxNumber: get(newValues, 'ticketSection.pfxNumber'),
            sfxNumber: get(newValues, 'ticketSection.sfxNumber'),
            networkTicketNumber: formatTicketNumber(get(newValues, 'ticketSection.pfxNumber'), get(newValues, 'ticketSection.sfxNumber')),
            formType: get(newValues, 'ticketSection.formType'), // checknout!!!!!!!!
            region: get(newValues, 'ticketSection.region'),
            clientId: get(newValues, 'ticketSection.clientId'),
            dealerNumber: get(newValues, 'ticketSection.dealerNumber'),
        },

        attachments: (get(newValues, 'attachmentsSection.attachments') || []).map(item => ({
            ...item,
            isDealerTicketAttachment: true,
            dealerTicketId: get(newValues, 'ticketSection.uuid'),
        })),
        documentationUpdate: get(newValues, 'documentationUpdate'),
    };
}

export const initLoadedAseInternalTicket = (ticketData) => {
    const {lockIpn, lockTime, ...ticketSection} = get(ticketData, 'ticket', {default: {}});

    const originalSolutionGroup = get(ticketSection, "solutionGroup", {default: {}});
    ticketSection.solutionGroup = get(ticketSection, "solutionGroup.id", {default: {}});

    return {
        ticketSection: {...ticketSection},
        vehicleDetail: ticketData.vehicleDetail,
        problemDescription: ticketData.problemDescription,
        messages: ticketData.messages,
        documentationUpdate: ticketData.documentationUpdate,
        statusHistory: ticketData.logs,
        attachmentsSection: {attachments: ticketData.attachments},
        requestorSolutionGroup: originalSolutionGroup,
    };
};

export const initNewAseInternalTicket = (userDetail, initData) => {
    if (!userDetail.ipn || !userDetail.name || userDetail === '') {
        return undefined;
    }

    const data = {};

    const networkTicketNumber = get(initData, 'ticketReference.networkTicketNumber');

    data.ticketSection = {
        requestorName: userDetail.name,
        requestorIpn: userDetail.ipn,
        requestorRole: "",
        priorityTreatment: "",

        ...(networkTicketNumber && {networkTicketNumber: networkTicketNumber}),
    };

    if (get(userDetail, 'roles.ASE.sgEditor.sgPermission').length === 1) {
        const array = get(userDetail, 'roles.ASE.sgEditor.sgPermission')
        data.ticketSection.solutionGroup = array[0].id
    }

    data.vehicleDetail = {
        bvmLoaded: true,
        ...(get(initData, 'vin') && {vin: get(initData, 'vin')}),
        ...(get(initData, 'registrationNumber') && {registrationNumber: get(initData, 'registrationNumber')}),
        ...(get(initData, 'moreDetails') && {moreDetails: get(initData, 'moreDetails')}),
    };

    data.problemDescription = {
        ...(get(initData, 'issueOrigin') && {issueOrigin: get(initData, 'issueOrigin')}),
        ...(get(initData, 'partAssociatedNumber') && {partAssociatedNumber: get(initData, 'partAssociatedNumber')}),
        ...(get(initData, 'temporarySolution') && {temporarySolution: get(initData, 'temporarySolution')}),
        ...(get(initData, 'nodId') && {nodId: get(initData, 'nodId')}),
        ...(get(initData, 'repairMethod') && {repairMethod: get(initData, 'repairMethod')}),
        ...(get(initData, 'problemDesc') && {problemDesc: get(initData, 'problemDesc')}),
    };

    data.attachmentsSection = {
        ...(get(initData, 'attachments') && {attachments: get(initData, 'attachments')}),
    }

    if (initData && initData.ticketReference) {
        data.ticketReference = initData.ticketReference;
    }
    if (initData && initData.documentationUpdate) {
        data.documentationUpdate = initData.documentationUpdate
    }

    return data;
};

export const transformNewInternalTicketForSave = (values, editorSolutionGroups) => {
    const data = {};

    data.ticket = {
        ...values.ticketSection,
        status: undefined,
        uuid: v4(),
    };

    const editorSolutionGroup = editorSolutionGroups.find(x => x.id === data.ticket.solutionGroup);
    data.ticket.solutionGroup = {id: data.ticket.solutionGroup, name: editorSolutionGroup.name};

    if(values.vehicleDetail) {
        data.vehicleDetail = values.vehicleDetail
    }

    if(values.problemDescription) {
        data.problemDescription = values.problemDescription
    }

    if(values.documentationUpdate) {
        data.documentationUpdate = values.documentationUpdate
    }

    if (values.messagesSection && values.messagesSection.newMessage) {
        data.message = {
            uuid: v4(),
            newMessage: values.messagesSection.newMessage,
        };
    }

    const internalAttachments = get(values, 'attachmentsSection.newAttachments');
    const dealerAttachments = get(values, 'attachmentsSection.attachments');

    if ((internalAttachments && internalAttachments.length) || (dealerAttachments && dealerAttachments.length)) {
        data.newAttachments = [];

        internalAttachments && internalAttachments.forEach(attachment => {
            data.newAttachments.push({
                ...attachment,
                uuid: v4(),
            })
        });
        dealerAttachments && dealerAttachments.forEach(attachment => {
            data.newAttachments.push(attachment);
        });
    }

    if (values.ticketReference) {
        data.ticketReference = values.ticketReference;
    }

    return data;
};


export const transformUpdateAseInternalTicketForSave = (values) => {
    const {oldValues, newValues, submitBy} = values;
    const difference = diff(oldValues, newValues);
    const data = {};

    data.submitBy = submitBy
    data.ticket = {
        ...get(difference, 'ticketSection'),
        uuid: oldValues.ticketSection.uuid,
        pfxNumber: oldValues.ticketSection.pfxNumber,
        sfxNumber: oldValues.ticketSection.sfxNumber,
        status: newValues.ticketSection.status,
    };


    const newVehicleDetail = get(difference, 'vehicleDetail');
    if(newVehicleDetail) {
        data.vehicleDetail = newVehicleDetail
    }

    const newProblemDescription = get(difference, 'problemDescription');
    if(newProblemDescription) {
        data.problemDescription = newProblemDescription
    }

    const newDocumentationUpdate = get(difference, 'documentationUpdate');
    if(newDocumentationUpdate) {
        data.documentationUpdate = newDocumentationUpdate
    }

    const newMessage = get(difference, 'messagesSection.newMessage');
    if (newMessage) {
        data.message = {
            uuid: v4(),
            newMessage,
        };
    }

    if (get(difference, 'attachmentsSection.newAttachments', {default: false}) && difference.attachmentsSection.newAttachments.length > 0) {
        data.newAttachments = [];
        difference.attachmentsSection.newAttachments.forEach(attachment => {
            data.newAttachments.push({
                uuid: v4(),
                ...attachment,
            })
        })
    }

    return data;
};
